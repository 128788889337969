import React from "react";

import Footer from "../../Frontend/Footer/Footer";
import ResponsiveAppBar from "../../Frontend/NavBar/navbar";
import { Box } from "@mui/material";
function Layout(props) {
  const { bgColor = "" } = props;
  return (
    <Box component={"main"}>
      <ResponsiveAppBar></ResponsiveAppBar>
      {props.children}
      <Footer bgColor={bgColor}></Footer>
    </Box>
  );
}

export default Layout;
