import { Box, Checkbox, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import QuantityButtonGroup from "./QuantityButtonGroup";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useDispatch, useSelector } from "react-redux";
import { removeItem } from "../../../redux/slice/UserSlice";

function CartRow({
  title,
  cartIndex,
  price,
  amount,
  image,
  country,
  stock,
  id,
  category,
  variant_id,
  handleCartCheck,
  checkOut = false,
  isReCheckOut = false,
}) {
  // const [isRemoved, setIsRemoved] = useState(false);
  const { shopCurrency, currencyRate, shopCountry } = useSelector(
    (state) => state.shop
  );
  const [quantity, setQuantity] = useState(amount);
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  const remove = () => {
    handleCartCheck(cartIndex);
    dispatch(
      removeItem({
        price: price,
        quantity: quantity,
        id: id,
        index: cartIndex,
        product_variations_id: variant_id,
      })
    );
  };
  // useEffect(() => {
  //   if (isRemoved) handleCartCheck(cartIndex);
  // }, [isRemoved, handleCartCheck, cartIndex]);

  return (
    <Box sx={{ display: "block" }}>
      <Grid container spacing={2} sx={{ p: "1rem" }}>
        <Grid items xs={12} sm={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: {
                xs: "center",
                sm: "center",
              },
            }}
          >
            {!checkOut && (
              <Box sx={{ width: "2rem" }}>
                <Checkbox
                  disabled={!country?.includes(shopCountry)}
                  checked={checked}
                  inputProps={{ "aria-label": "controlled" }}
                  onClick={() => {
                    setChecked((prev) => !prev);
                    handleCartCheck(cartIndex);
                  }}
                />
              </Box>
            )}

            <Box
              sx={{
                minHeight: 150,
                height: "10rem",

                width: {
                  xs: "100%",
                  md: "50%",
                },

                minWidth: "8rem",
              }}
            >
              <img
                src={`${image}`}
                alt="item-img"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "center",
                  objectFit: "contain",
                  borderRadius: "1rem",
                }}
              />
            </Box>
            <Box
              sx={{
                p: 2,
                maxWidth: {
                  xs: "auto",
                  sm: "10rem",
                },
              }}
            >
              <Typography
                variant="body1"
                sx={{ color: "gray", fontWeight: "bold" }}
              >
                {category}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100%",
                  fontWeight: "bolder",
                  color: "rgb(18 ,82,166)",
                }}
              >
                {title}
              </Typography>
              <Box>
                <Typography variant="body2" sx={{}}>
                  Attribute
                </Typography>
                <Typography variant="body2" sx={{}}>
                  Wire
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid items xs={12} sm={2}>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              justifyContent: {
                xs: "flex-start",
                sm: "center",
              },
              alignItems: "center",
              my: {
                xs: 2,
              },
            }}
          >
            <Typography>
              {shopCurrency} {price}{" "}
            </Typography>
          </Box>
        </Grid>
        <Grid items xs={12} sm={4}>
          {!checkOut ? (
            <Box
              sx={{
                display: "flex",
                height: "100%",
                position: "relative",
                justifyContent: {
                  xs: "flex-start",
                  sm: "center",
                },
                alignItems: "center",
                flexDirection: "column",
                my: {
                  xs: 2,
                },
              }}
            >
              <QuantityButtonGroup
                value={quantity}
                cart={true}
                id={id}
                variant_id={variant_id}
                setValue={setQuantity}
                price={price}
                max={stock}
              />
              <Box sx={{ position: "absolute", bottom: 20 }}>
                max quantity - {stock}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                my: {
                  xs: 2,
                },
              }}
            >
              Quantity : {quantity}
            </Box>
          )}
        </Grid>
        <Grid items xs={12} sm={2} sx={{ position: "relative" }}>
          {!checkOut && (
            <Box sx={{ position: "absolute", top: 0, right: 0 }}>
              <DeleteForeverIcon
                sx={{
                  color: "rgba(255,42,0,1)",
                  "&:hover": {
                    cursor: "pointer",
                    color: "rgba(255,42,0,0.5)",
                  },
                }}
                onClick={() => remove()}
              />
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              height: "100%",
              justifyContent: {
                xs: "flex-start",
                sm: "center",
              },
              my: {
                xs: 2,
              },
              alignItems: "center",
            }}
          >
            {!isReCheckOut && !country?.includes(shopCountry) ? (
              <span style={{ color: "red" }}>
                Not available in selected country .
              </span>
            ) : (
              <Typography sx={{ fontWeight: "bold", color: "rgb(18 ,82,166)" }}>
                {shopCurrency} {quantity * price}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid items xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default CartRow;
